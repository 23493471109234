export function capitalize(string: string) {
  if (string == null) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function fullname(object: any) {
  if (object == null || object == undefined) {
    throw new Error("fullname filter: object is null or undefined");
  }

  const list = [];

  if (object.last_name) {
    list.push(capitalize(object.last_name));
  }

  if (object.first_name) {
    list.push(capitalize(object.first_name));
  }

  if (object.company_name) {
    list.push(`[${capitalize(object.company_name)}]`);
  }

  return list.join(" ");
}

export function printCustomer(customer: any) {
  if (customer == null || customer == undefined) {
    throw new Error("printCustomer customer: object is null or undefined");
  }

  const list = [];

  if (customer.billing_person_last_name) {
    list.push(capitalize(customer.billing_person_last_name));
  }

  if (customer.billing_person_first_name) {
    list.push(capitalize(customer.billing_person_first_name));
  }

  if (customer.billing_company_name) {
    list.push(`[${capitalize(customer.billing_company_name)}]`);
  }

  return list.join(" ");
}

export function dateStringToHumanizedDate(dateStr: string, size: "long" | "short" | "narrow" = "long", year: boolean = true) {
  if (dateStr == null) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = { weekday: size, month: size, day: "numeric" };

  if (year) {
    options.year = "numeric";
  }

  return new Date(Date.parse(dateStr.substring(0, 19))).toLocaleDateString("fr-FR", options);
}

export function dateToHumanizedDate(date: Date, size: "long" | "short" | "narrow" = "long", year: boolean = true) {

  const options: Intl.DateTimeFormatOptions = { weekday: size, month: size, day: "numeric" };

  if (year) {
    options.year = "numeric";
  }

  return date.toLocaleDateString("fr-FR", options);
}

export function dateToHumanizedTime(date: Date) {
  const options: Intl.DateTimeFormatOptions = {hour: "2-digit", minute:"2-digit"};
  return date.toLocaleTimeString("fr-FR", options);
}

export function dateStringToHumanizedTime(dateStr: string) {
  if (dateStr == null) {
    return null;
  }

  return dateToHumanizedTime(new Date(Date.parse(dateStr.substring(0, 19))));
}

export function dateStringToHumanizedDateTime(dateStr: string, size: "long" | "short" | "narrow" | undefined = "long", year: boolean = false) {
  if (dateStr == null) {
    return null;
  }

  return `${dateStringToHumanizedDate(dateStr, size, year)} à ${dateStringToHumanizedTime(dateStr)}`;
}

export function dateStringToHumanizedDateTimeWithYear(dateStr: string, size: "long" | "short" | "narrow" | undefined = "long") {
  if (dateStr == null) {
    return null;
  }

  return `${dateStringToHumanizedDate(dateStr, size, true)} à ${dateStringToHumanizedTime(dateStr)}`;
}

export function dateToISOString(date: Date) {
  const pad = function (num: number) {
    return (num < 10 ? "0" : "") + num;
  };

  return date.getFullYear() +
      "-" + pad(date.getMonth() + 1) +
      "-" + pad(date.getDate()) +
      "T" + pad(date.getHours()) +
      ":" + pad(date.getMinutes()) +
      ":" + pad(date.getSeconds());
}

export function numberWithSpaces(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
}

export function hoursAndMinutesToIsoTimeString(hours: number, minutes: number) {
  const hoursStr = String(hours).padStart(2, "0");
  const minutesStr = String(minutes).padStart(2, "0");
  const secondsStr = "00"; // Assuming seconds are not provided, defaulting to '00'

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function secondsToHumanDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  if (minutes === 0) {
    return `${hours}h`;
  }

  return `${hours}h${minutes}`;
}


export function dateStringToRelativeDateTime(inputDate) {
  const currentDate = new Date();
  const targetDate = new Date(inputDate);

  const timeDifference = targetDate - currentDate;
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;
  const oneMonth = 30.44 * oneDay; // Environ le nombre moyen de jours dans un mois
  const oneYear = 365.25 * oneDay; // Environ le nombre moyen de jours dans une année

  const daysDifference = Math.round(timeDifference / oneDay);
  const weeksDifference = Math.round(timeDifference / oneWeek);
  const monthsDifference = Math.round(timeDifference / oneMonth);
  const yearsDifference = Math.round(timeDifference / oneYear);

  if (yearsDifference > 0) {
    if (yearsDifference === 1) {
      return "Dans un an";
    } else {
      return `Dans ${yearsDifference} ans`;
    }
  }

  if (yearsDifference < 0) {
    if (yearsDifference === -1) {
      return "Il y a un an";
    } else {
      return `Il y a ${-yearsDifference} ans`;
    }
  }

  if (monthsDifference > 0) {
    if (monthsDifference === 1) {
      return "Dans un mois";
    } else {
      return `Dans ${monthsDifference} mois`;
    }
  }

  if (monthsDifference < 0) {
    if (monthsDifference === -1) {
      return "Il y a un mois";
    } else {
      return `Il y a ${-monthsDifference} mois`;
    }
  }

  if (weeksDifference > 0) {
    if (weeksDifference === 1) {
      return "Dans une semaine";
    } else {
      return `Dans ${weeksDifference} semaines`;
    }
  }

  if (weeksDifference < 0) {
    if (weeksDifference === -1) {
      return "Il y a une semaine";
    } else {
      return `Il y a ${-weeksDifference} semaines`;
    }
  }

  if (daysDifference > 0) {
    if (daysDifference === 1) {
      return "Demain";
    } else {
      return `Dans ${daysDifference} jours`;
    }
  }

  if (daysDifference < 0) {
    if (daysDifference === -1) {
      return "Hier";
    } else {
      return `Il y a ${-daysDifference} jours`;
    }
  }

  return "Aujourd'hui";

  
}
