<template>
  <LoaderView v-if="schema == null" />
  <div v-else>
    <div class="vertical-spaced-container">
      <div>
        <div class="flow-spaced-container">
          <FormField
            :schema="schema.title"
            :errors="validationErrors?.title"
            style="width: 100px;">
            <select
              v-model="internalContact.title"
              class="form-select"
              :disabled="enabledFields != null && !enabledFields.includes('title')">
              <option value="mme">
                Madame
              </option>
              <option value="mr">
                Monsieur
              </option>
              <option value="mx">
                Neutre
              </option>
            </select>
          </FormField>

          <FormField
            :schema="schema.last_name"
            :errors="validationErrors?.last_name"
            style="width: 120px;">
            <input v-model="internalContact.last_name" type="text" class="form-control" :disabled="enabledFields != null && !enabledFields.includes('last_name')">
          </FormField>

          <FormField
            :schema="schema.first_name"
            :errors="validationErrors?.first_name"
            style="width: 120px;">
            <input v-model="internalContact.first_name" type="text" class="form-control" :disabled="enabledFields != null && !enabledFields.includes('first_name')">
          </FormField>
        </div>
      </div>
    </div>

    <div>
      <div class="flow-spaced-container">
        <FormField
          :schema="schema.company_vat"
          :errors="validationErrors?.company_vat"
          style="width: 230px;">
          <VatNumber v-model="internalContact.company_vat" @change="checkCompanyVATDoNotExists" />
          <small
            v-if="existingCustomerWithSaveCompanyVAT"
            class="form-text text-red">
            <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSaveCompanyVAT.id)">Ce client</button> existes déjà avec le même numéro de TVA
          </small>
        </FormField>

        <FormField
          :schema="schema.company_name"
          :errors="validationErrors?.company_name"
          style="width: 120px;">
          <input
            v-model="internalContact.company_name"
            type="text"
            class="form-control"
            :disabled="enabledFields != null && !enabledFields.includes('company_name')"
            @blur="checkCompanyNameDoNotExists">
          <small
            v-if="existingCustomerWithSaveCompanyName"
            class="form-text text-red">
            <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSaveCompanyName.id)">Ce client</button> existes déjà avec le même nom de société
          </small>
        </FormField>
      </div>
    </div>

    <FormField
      :schema="schema.phone_number"
      :errors="validationErrors?.phone_number"
      style="width: 300px;">
      <PhoneNumber
        v-model="internalContact.phone_number"
        :disabled="enabledFields != null && !enabledFields.includes('phone_number')"
        @blur="checkPhoneNumberDoNotExists" />
      <small
        v-if="existingCustomerWithSavePhoneNumber"
        class="form-text text-red">
        <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSavePhoneNumber.id)">Ce client</button> existes déjà avec le même numéro de téléphone
      </small>
    </FormField>

    <div>
      <div class="flow-spaced-container">
        <FormField
          :schema="schema.email"
          :errors="validationErrors?.email"
          style="width: 230px;">
          <input
            v-model="internalContact.email"
            type="text"
            class="form-control"
            :disabled="enabledFields != null && !enabledFields.includes('email')">
          <small
            v-if="emailIsNotValid"
            class="text-red">
            Email invalide
          </small>
        </FormField>

        <FormField
          :schema="schema.language"
          :errors="validationErrors?.language"
          style="width: 120px;">
          <select
            v-model="internalContact.language"
            class="form-select"
            :disabled="enabledFields != null && !enabledFields.includes('language')">
            <option value="fr">
              Français
            </option>
            <option value="nl">
              Néerlandais
            </option>
          </select>
        </FormField>
      </div>
    </div>      
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import PhoneNumber from "@/components/vueComponents/input/PhoneNumber.vue";
import VatNumber from "@/components/vueComponents/input/VatNumber.vue";

export default {
  name: "ContactForm",
  components: {
    FormField,
    LoaderView,
    PhoneNumber,
    VatNumber,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: false,
      default: null,
    },
    checkExistingCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabledFields: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      internalContact: this.modelValue,
      schema: null,
      existingCustomerWithSaveCompanyName: null,
      existingCustomerWithSaveCompanyVAT: null,
      existingCustomerWithSavePhoneNumber: null,
    };
  },
  computed: {
    emailIsNotValid() {
      return this.internalContact.email != null && this.internalContact.email.length > 0 && !this.internalContact.email.includes("@");
    },
    
  },
  watch: {
    modelValue(newVal) {
      this.internalContact = newVal;
    },
    internalContact(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.contact.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    checkCompanyNameDoNotExists() {
      if (!this.checkExistingCustomer) {
        return;
      }

      if (!this.internalContact.company_name) {
        this.existingCustomerWithSaveCompanyName = null;
        return;
      }

      this.$services.customer.list({company_name: this.internalContact.company_name})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSaveCompanyName = response.data.results[0];
          } else {
            this.existingCustomerWithSaveCompanyName = null;
          }
        });

    },
    checkCompanyVATDoNotExists() {
      if (!this.checkExistingCustomer) {
        return;
      }

      if (!this.internalContact.company_vat) {
        this.existingCustomerWithSaveCompanyVAT = null;
        return;
      }

      this.$services.customer.list({company_vat: this.internalContact.company_vat})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSaveCompanyVAT = response.data.results[0];
          } else {
            this.existingCustomerWithSaveCompanyVAT = null;
          }
        });
    },
    checkPhoneNumberDoNotExists() {
      if (!this.checkExistingCustomer || this.internalContact.phone_number == null || this.internalContact.phone_number.length == 0) {
        this.existingCustomerWithSavePhoneNumber = null;
        return;
      }

      if (!this.internalContact.phone_number) {
        this.existingCustomerWithSavePhoneNumber = null;
        return;
      }

      this.$services.customer.list({phone_number: this.internalContact.phone_number})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSavePhoneNumber = response.data.results[0];
          } else {
            this.existingCustomerWithSavePhoneNumber = null;
          }
        });
    },
    openCustomerPageInNewTab(customer_id) {
      const routeData = this.$router.resolve({name: "customer", params: {id: customer_id}});
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>

</style>